import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.svg"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--20"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">APPSTORE-OPTIMERING</h2>
                  <p>Från osynlig till fler nerladdningar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <picture>
                            <source
                              className="w-100"
                              srcSet="/assets/images/service/appstore.webp"
                              type="image/webp"
                            />
                            <img
                              className="w-100"
                              src="/assets/images/service/appstore.jpg"
                              alt="Service Images"
                            />
                          </picture>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Idag finns det över 3 miljoner appar. Många lägger
                            mycket tid och energi på att ta fram något riktigt
                            bra men ingen hittar just din app.
                          </p>
                          <p>
                            Det problemet löser man genom ASO eller att optimera
                            för att hamna högt upp i sökresultaten. Något som
                            leder till fler nedladdningar och att ni hamnar
                            högre upp på topplistorna.
                          </p>
                          <h4 className="title">Process</h4>
                          <ul className="liststyle">
                            <li>
                              Vi skaffar en tydlig bild av er app / användare
                            </li>
                            <li>Vi optimerar beskrivning, bilder, mm</li>
                            <li>
                              Vi mäter vad som ger resultat och byter ut det som
                              inte ger resultat
                            </li>
                            <li>
                              Steg 2 och 3 igen tills vi når ett bra resultat
                            </li>
                            <li>
                              Ni får en slutgiltig rapport på vad som gav mest
                              resultat och skillanden
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
